<template>
    <v-overlay color="white" :opacity="0.70" :value="true">
        <v-container class="text-center">
            <lottie-animation path="./_/img/burger-loop.json"
                              :width="192"
                              :height="192"/>
            <div class="nolo-h6 mt-8 mx-8 black--text">{{ $t('message.submittingOrderToStore', {storeName:
                sessStoreName})}}
            </div>

        </v-container>
    </v-overlay>
</template>

<script>

    import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs
    import APIOrder from "../../api/APIOrder";

    export default {
        name: "OrderCompleting",
        mounted() {
            this.checkAccepted();
        },
        data() {
            return {
                isAccepted: false,
                inError: false,
                notComplete: false,
                actualFulfilmentTime: false
            }
        },
        components: {
            LottieAnimation
        },
        methods: {
            checkAccepted() {
                const me = this;
                APIOrder.getOrderStatus(this, this.sessOrderId)
                    .then((response) => {
                        if (response.data.data.order_status_id !== APIOrder.Constants.OrderStatus.COMPLETE) {
                            me.notComplete = true;
                        }
                        me.isAccepted =
                            response.data.data.stage_id === APIOrder.Constants.OrderStage.ACCEPTED
                            || response.data.data.stage_id === APIOrder.Constants.OrderStage.PREPARING
                            || response.data.data.stage_id === APIOrder.Constants.OrderStage.COMPLETE;
                        me.inError = response.data.data.stage_id === APIOrder.Constants.OrderStage.REJECTED;
                        me.actualFulfilmentTime = response.data.data.fulfilment_datetime;
                    })
                    .catch(() => {
                    })
                    .finally(() => {
                            if (me.isAccepted) {
                                this.$store.commit('session/orderProgress', APIOrder.Constants.OrderProgress.COMPLETE);
                                this.$store.commit('session/isComplete', true);
                                this.$store.commit('session/isFailed', false);
                                this.$emit('order-complete', me.actualFulfilmentTime);
                            } else if (me.inError) {
                                this.$store.commit('session/orderProgress', APIOrder.Constants.OrderProgress.FAILED);
                                this.$store.commit('session/isComplete', false);
                                this.$store.commit('session/isFailed', true);
                                this.$emit('order-failed');
                           } else if (me.notComplete) {
                                // we shouldn't be here so back out quick.
                                this.$router.replace({name: 'order'});
                            } else {
                                this.$store.commit('session/orderProgress', APIOrder.Constants.OrderProgress.COMPLETING);
                                me.timeout = window.setTimeout(me.checkAccepted, 5000);
                            }
                        }
                    )
            }
        },
        computed: {
            sessStoreName() {
                let store = this.$store.getters['app/stores'][this.$store.getters['session/storeId']];
                if (typeof store === 'undefined') return '##STORENAME##';
                return store.name;
            },
            sessOrderId() {
                return this.$store.getters['session/orderId'];
            },
        }

    }
</script>

<style scoped>

</style>
