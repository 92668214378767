<template>
    <v-row dense>
        <v-col v-if="sessOrderTypeRaw == 'Collection'" cols="12" class="black--text">
            <p v-if="isCollectPlus" v-html="$t('orderInstructions.Collection.collectPlus')"></p>
            <p v-else v-html="$t('orderInstructions.Collection.fries')"></p>
            {{ $t('orderInstructions.Collection.instructions') }}
        </v-col>
        <v-col v-else cols="12" class="black--text">
            <p v-html="$t('orderInstructions.' + sessOrderTypeRaw)"></p>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: "OrderInstructions",
        computed: {
            sessStore() {
                let store = this.$store.getters['app/stores'][this.$store.getters['session/storeId']];
                if (typeof store === 'undefined') return '';
                return store.name + ", " + store.address;
            },
            sessOrderTypeRaw() {
                return this.$store.getters['session/orderType'];
            },
            isCollectPlus() {
              let store = this.$store.getters['app/stores'][this.$store.getters['session/storeId']];
              if (typeof store === 'undefined') return false;
              return store.collectPlus.enabled;
            }
        }
    }
</script>

<style scoped>

</style>
