<template>
    <v-row dense class="pt-2 pb-0">
        <v-col cols="12" md="6" class="black--text">
            <p v-html="$t('orderInstructions.' + sessOrderTypeRaw + '.fries')"></p>
            <p v-html="$t('orderInstructions.' + sessOrderTypeRaw + '.instructions')"></p>
        </v-col>
        <v-col cols="12" md="6" class="mb-2">
            <curbside-map :store="sessStore" />
        </v-col>
    </v-row>
</template>

<script>
    import CurbsideMap from "../CurbsideMap";
    export default {
        name: "OrderInstructionsCurbside",
        components: {CurbsideMap},
        computed: {
            sessStore() {
                let store = this.$store.getters['app/stores'][this.$store.getters['session/storeId']];
                return store;
            },
            sessOrderTypeRaw() {
                return this.$store.getters['session/orderType'];
            }
        }
    }
</script>

<style scoped>

</style>
