<template>
    <v-row>
        <v-col cols="12" class="pa-5 black--text">
            <v-row class="mb-5 nolo-p2" no-gutters>
                <v-col class="shrink">
                    <v-icon v-if="tableService" class="mr-4">#</v-icon>
                    <v-icon v-else class="mr-2">mdi-clock-outline</v-icon>
                </v-col>
                <v-col v-if="tableService">
                    {{ $t('label.tableNumber') }}: {{ tableNumber }}
                </v-col>
                <v-col v-else>
                    {{ sessOrderType }}, {{ actualFulfilmentTime }}
                </v-col>
            </v-row>
            <v-row class="nolo-p2" no-gutters>
                <v-col class="shrink"><v-icon class="mr-2">mdi-map-marker-outline</v-icon></v-col>
                <v-col>
                    {{ sessStore }}
                </v-col>
            </v-row>
        </v-col>
    </v-row>

</template>

<script>
    export default {
        name: "OrderInfo",
        props: {
            actualFulfilmentTime: {type: String, required: true}
        },
        computed: {
            sessStore() {
                let store = this.$store.getters['app/stores'][this.$store.getters['session/storeId']];
                if (typeof store === 'undefined') return '';
                return store.name + ", " + store.address;
            },
            sessOrderTypeRaw() {
                return this.$store.getters['session/orderType'];
            },
            tableNumber() {
                return this.$store.getters['session/tableNumber'];
            },
            tableService() {
                return this.sessOrderTypeRaw === 'Table';
            },
            curbside() {
                return this.sessOrderTypeRaw === 'Curbside';
            },
            sessOrderType() {
                return this.$t('orderType.' + this.sessOrderTypeRaw);
            },
            sessFulfilmentDate() {
                let fulfilmentDate = this.$store.getters['session/fulfilmentDate'];

                return typeof fulfilmentDate === 'undefined' || fulfilmentDate === null ? {desc: ''} : fulfilmentDate;
            },
            sessFulfilmentTime() {
                let fulfilmentTime = this.$store.getters['session/fulfilmentTime'];

                return typeof fulfilmentTime === 'undefined' || fulfilmentTime === null ? {desc: ''} : fulfilmentTime;
            },
        }
    }
</script>

<style scoped>

</style>
