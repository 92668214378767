<template>
    <page-layout>
        <order-completing v-if="!complete && !failed" @order-failed="markOrderFailed" @order-complete="markOrderComplete" />

        <order-complete v-if="complete" :actual-fulfilment-time="actualFulfilmentTime"/>
        <order-failed v-if="failed" />
    </page-layout>
</template>

<script>
    import OrderComplete from "../components/OrderComplete/OrderComplete";
    import OrderCompleting from "../components/OrderComplete/OrderCompleting";
    import OrderFailed from "../components/OrderComplete/OrderFailed";
    import {mapGetters} from "vuex";

    export default {
        name: "OrderCompletePage",
        data() { return {
            complete: false,
            failed: false,
            actualFulfilmentTime: false,
        }},
        components: {OrderFailed, OrderCompleting, OrderComplete },
        methods: {
            markOrderFailed() {
                this.failed = true;
            },
            markOrderComplete(actualFulfilmentTime) {
                this.complete = true;
                this.actualFulfilmentTime = actualFulfilmentTime;

                if(typeof window.fbq != 'undefined') {
                  window.fbq('track', 'Purchase', {value: this.orderTotal, currency: this.currency});
                }

                if(typeof window.ytag != 'undefined') {
                  var script = document.createElement("script");
                  script.type = "text/javascript";
                  script.src = "https://assets.sitescdn.net/ytag/ytag.min.js";
                  document.body.appendChild(script);
                  window.ytag('conversion', {'cid': process.env.VUE_APP_YEXT_ID, 'cv': this.orderTotal});
                }
            },
            totalItemValue(code) {
              let result = 0;
              for (let t in this.orderTotals) {
                if (this.orderTotals[t].code === code) {
                  result = this.orderTotals[t].value;
                  break;
                }
              }
              return result.toFixed(2);
          }
        },
        computed: {
          ...mapGetters({
          currency: 'session/currency',
          orderTotals: 'session/orderTotals'
        }),
        basketItems() {

          let items = [];
          for (let b in this.basket) {
            let bItem = this.basket[b];
            let variant = [];
            for (let mg in bItem.modifiers) {
              const modGrp = bItem.modifiers[mg];
              for (let m in modGrp.modifiers) {
                variant.push(modGrp.modifiers[m].code);
              }
            }
            items.push({
              id: bItem.code,
              name: bItem.name[this.language],
              category: bItem.categoryCode,
              quantity: bItem.quantity,
              price: (bItem.price / 100.0).toFixed(2),
              variant: variant.join(',')
            });
          }

          return items;
        },
        orderTotal() {
          return this.totalItemValue('total');
        }
      }
    }
</script>

<style scoped>

</style>

