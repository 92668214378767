<template>
        <v-container class="thank-you-container pt-6 pb-12 pt-sm-12 mb-sm-16">
            <div class="nolo-h3 text-center mb-8">{{ $t('sorryOrderFailed') }}</div>
            <v-card class="py-6">
                <v-alert :value="true" class="mx-0" color="error">{{ $t('error.orderFailed')}}</v-alert>

                <v-divider class="my-5 dashed"></v-divider>

                <div class="nolo-h5 px-8">{{ $t('orderNumber', { orderId: sessOrderId }) }}</div>

                <v-divider class="my-5 dashed"></v-divider>

                <div class="px-5">
                    <order-info actual-fulfilment-time=""/>
                </div>


            </v-card>
        </v-container>
</template>

<script>
    import ShopCurrency from "../../mixins/ShopCurrency";
    import OrderInfo from "./OrderInfo";
    import OrderInstructions from "./OrderInstructions";
    import OrderInstructionsCurbside from "./OrderInstructionsCurbside";

    export default {
        name: "OrderFailed",
        // eslint-disable-next-line vue/no-unused-components
        components: {OrderInstructionsCurbside, OrderInstructions, OrderInfo},
        data() {
            return {
            }
        },
        mixins: [ShopCurrency],
        methods: {
            lineTotal(basketItem) {
                return basketItem.quantity * (basketItem.price + basketItem.modifierPrice);
            },
            loadedComponents() {
                var loaded = [];
                var components = this.$options.components;
                for (var key in components) {
                    loaded.push(key);
                }
                return loaded;
            },
            componentExists(component) {
                var components = this.loadedComponents.call(this);
                if (components.indexOf(component) !== -1) {
                    return true;
                }
                return false;
            }
        },
        computed: {
          language() {
            return this.appLanguage;
          },
          currency() {
            return this.appCurrency;
          },
            orderedUpsells() {
                return this.sessUpsells.filter(el => el.quantity > 0);
            },
            orderTotalsExcTotal() {
                try {
                    return this.$store.getters['session/orderTotals'].filter((el) => el.code !== 'total');
                } catch {
                    return [];
                }
            },
            sessBasket() {
                return this.$store.getters['session/basket'];
            },
            sessUpsells() {
                return this.$store.getters['session/upsells'];
            },
            sessOrderId() {
                return this.$store.getters['session/orderId'];
            },
            sessOrderTypeRaw() {
                return this.$store.getters['session/orderType'];
            },
            totalPrice() {
                let total = 0;
                for (let b in this.sessBasket) {
                    total += this.lineTotal(this.sessBasket[b]);
                }
                return total / 100;
            },
            totalItems() {
                let total = 0;
                for (let b in this.basket) {
                    total += this.basket[b].quantity;
                }
                return total;
            },

            instructionComponentForOrderType() {
                let customComponent = 'OrderInstructions' + (this.sessOrderTypeRaw);
                if (this.componentExists(customComponent)) {
                    return customComponent;
                } else {
                    return 'OrderInstructions';
                }
            }
        },
    }
</script>

<style scoped>

    .thank-you-container {
        max-width: 730px;
    }

    .modifierItem + .modifierItem:before {
        content: ", ";
    }

</style>

