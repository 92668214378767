<template>
    <div v-resize="resizedOuter" ref="outer" class="fill-height">
        <div @click="ready = true">
            <l-map ref="map" v-if="ready"
                   :style="mapStyle"
                   :options="{ zoomControl: false, static: true, dragging: false }"
                   :zoom="zoom"
                   :max-zoom="15"
                   @update:zoom="zoomUpdated"
                   :center="center">
                <l-tile-layer :url="url"></l-tile-layer>
                <l-marker :lat-lng="store.location"
                          :icon="getIconForStore()"/>
<!--                <l-marker :lat-lng="customerLocation" :icon="getIconForCustomer()" />-->
            </l-map>
        </div>
    </div>
</template>

<script>

    import Vue from 'vue';

    import MapMarkers from "./Maps/MapMarkers";
    import {LMap, LTileLayer, LMarker} from 'vue2-leaflet';
    import 'leaflet/dist/leaflet.css'
    import * as Vue2Leaflet from 'vue2-leaflet'

    Vue.use(Vue2Leaflet);

    import L from 'leaflet';

    Vue.component('l-map', LMap);
    Vue.component('l-tile-layer', LTileLayer);
    Vue.component('l-marker', LMarker);

    export default {
        name: "CurbsideMap",
        data() {
            return {
                url: 'https://api.maptiler.com/maps/bright/{z}/{x}/{y}.png?key=' + process.env.VUE_APP_MAPTILER_KEY,
                zoom: 12,
                bounds: null,
                ready: false,
                center: [51, 0],
                mapHeight: 200,
                currentStore: null
            };
        },
        props: {
            store: {type: Object, required: true},
            // customerLocation: {type: Object, required: true}
        },
        mounted() {
            const me = this;
            this.$nextTick(function () {
                setTimeout(me.invalidateSize, 500)
            });
        },
        methods: {
            getIconForStore() {
                let marker = MapMarkers.getMarkerNo(-1, true);
                return L.icon({
                    iconUrl: marker,
                    iconSize: [26, 36],
                    iconAnchor: [13, 36],
                });
            },
            getIconForCustomer() {
                let marker = MapMarkers.getMarkerNo(0, false);
                return L.icon({
                    iconUrl: marker,
                    iconSize: [24, 24],
                    iconAnchor: [12, 12],
                });
            },
            resizedOuter() {
                let outer = this.$refs.outer;
                this.mapHeight = outer.clientHeight;
            },
            zoomUpdated(zoom) {
                this.zoom = zoom;
            },
            invalidateSize() {
                this.ready = true;
                this.$nextTick(this.updateBounds);
            },
            updateBounds() {
                let markerArray = [];
                // markerArray.push(L.marker(this.customerLocation));
                markerArray.push(L.marker(this.store.location));
                var group = L.featureGroup(markerArray);
                this.$refs.map.fitBounds(group.getBounds().pad(0.1));
                // this.$refs.map.dragging.disable();
            }
        },
        computed: {
            mapStyle() {
                return 'width: 100%; height: ' + this.mapHeight + 'px !important;';
            }
        }
    }
</script>

<style scoped>

</style>
