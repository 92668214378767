<template>
    <v-container class="thank-you-container pt-6 pb-12 pt-sm-12 mb-sm-16">

        <g-tag-purchase/>

        <div class="nolo-h3 text-center mb-8">{{ $t('thankYou') }}</div>
        <v-card class="py-6">
            <div class="nolo-h5 px-8">{{ $t('orderNumber', { orderId: sessOrderId }) }}</div>

            <v-divider class="my-5 dashed"></v-divider>

            <div class="px-5">
                <order-info :actual-fulfilment-time="actualFulfilmentTime"/>
            </div>

            <v-divider class="my-5 dashed"></v-divider>

            <div class="nolo-p2 px-8">
                <component :is="instructionComponentForOrderType"/>
                <p class="mt-4 font-italic font-weight-bold" v-html="$t('message.checkYourJunk')"></p>
                <p v-if="$t('message.lawAnnotation') !== ''" class="mt-4 font-italic" v-html="$t('message.lawAnnotation')"></p>
            </div>

            <v-divider class="my-5 dashed"/>

            <div class="nolo-h5 px-8 mb-5">{{ $t('orderDetails') }}</div>
            <v-row class="px-4 nolo-p2">
                <v-col cols="12" v-for="(item,itemIdx) in sessBasket" :key="itemIdx">
                    <v-row no-gutters class="px-4 black--text">
                        <v-col cols="8" class="font-weight-bold">{{sessBasket[itemIdx].quantity}}x
                            {{item.name[language]}}
                        </v-col>
                        <v-col cols="4" class="text-right text-no-wrap">
                            {{ currencyFormat(lineTotal(item))}}
                        </v-col>
                        <v-col cols="10" v-if="item.modifiers">
                            <div v-for="(modGrp, modGrpIdx) in item.modifiers" :key="modGrpIdx">
                                                <span class="modifierItem" v-for="(mod, modIdx) in modGrp.modifiers"
                                                      :key="modIdx">{{ mod.quantity === 1 ? '' : mod.quantity + 'x' }}{{mod.name[language] }}</span>
                            </div>
                            <div v-if="isPlain(item)">{{ $t('plain') }}</div>
                        </v-col>
                    </v-row>
                    <v-row dense v-if="itemIdx !== sessBasket.length - 1">
                        <v-col cols="12">
                            <v-divider class="my-2 dashed"></v-divider>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col class="py-0" cols="12" v-for="(item,itemIdx) in orderedUpsells" :key="'u-' + itemIdx">
                    <v-row dense>
                        <v-col cols="12">
                            <v-divider class="my-2 dashed"></v-divider>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="px-4 nolo-p3 black--text">
                        <v-col cols="8" class="font-weight-bold">{{item.quantity}}x {{item.name}}</v-col>
                        <v-col cols="4" class="text-right text-no-wrap">
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <v-divider class="my-5 dashed"></v-divider>

            <v-row class="nolo-p2 px-8 nolo-dark-grey--text" v-for="orderTotal in orderTotalsExcTotal"
                   :key="orderTotal.code">
                <v-col> {{ orderTotal.title }}</v-col>
                <v-spacer/>
                <v-col class="text-right"> {{ orderTotal.text }}</v-col>
            </v-row>

            <v-row class="nolo-p2 px-8 font-weight-bold">
                <v-col v-if="orderTotalsExcTotal.length > 0" cols="12" class="px-3 pt-2 pb-0">
                    <v-divider class="my-3"></v-divider>
                </v-col>
                <v-col>
                    {{ $t('orderTotal') }}
                </v-col>
                <v-spacer/>
                <v-col class="text-right">
                    {{ orderTotalsOnlyTotal.text }}
                </v-col>
            </v-row>

        </v-card>
    </v-container>
</template>

<script>
    import ShopCurrency from "../../mixins/ShopCurrency";
    import OrderInfo from "./OrderInfo";
    import OrderInstructions from "./OrderInstructions";
    import OrderInstructionsCurbside from "./OrderInstructionsCurbside";
    import GTagPurchase from "../GTagPurchase";

    export default {
        name: "OrderComplete",
        // eslint-disable-next-line vue/no-unused-components
        components: {GTagPurchase, OrderInstructionsCurbside, OrderInstructions, OrderInfo},
        data() {
          return {}
        },
        mixins: [ShopCurrency],
        props: {
            actualFulfilmentTime: {type: String, required: true}
        },
        methods: {
            isPlain(item) {
                for (let m in item.modifiers) {
                    if (item.modifiers[m].groupCode === 'toppings') {
                        return item.modifiers[m].modifiers.length === 0;
                    }
                }
                return false;
            },
            lineTotal(basketItem) {
                return basketItem.quantity * (basketItem.price + basketItem.modifierPrice);
            },
            loadedComponents() {
                var loaded = [];
                var components = this.$options.components;
                for (var key in components) {
                    loaded.push(key);
                }
                return loaded;
            },
            componentExists(component) {
                var components = this.loadedComponents.call(this);
                if (components.indexOf(component) !== -1) {
                    return true;
                }
                return false;
            }
        },
        computed: {
            language() {
              return this.appLanguage;
            },
            currency() {
              return this.appCurrency;
            },
            orderedUpsells() {
                return this.sessUpsells.filter(el => el.quantity > 0);
            },
            orderTotalsExcTotal() {
                try {
                    return Object.values(this.$store.getters['session/orderTotals']).filter((el) => el.code !== 'total');
                } catch {
                    return [];
                }
            },
            orderTotalsOnlyTotal() {
                try {
                    return Object.values(this.$store.getters['session/orderTotals']).filter((el) => el.code === 'total')[0];
                } catch {
                    return { code: 'total', text: ''};
                }
            },
            sessBasket() {
                return this.$store.getters['session/basket'];
            },
            sessUpsells() {
                return this.$store.getters['session/upsells'];
            },
            sessOrderId() {
                return this.$store.getters['session/orderId'];
            },
            sessOrderTypeRaw() {
                return this.$store.getters['session/orderType'];
            },
            totalItems() {
                let total = 0;
                for (let b in this.basket) {
                    total += this.basket[b].quantity;
                }
                return total;
            },

            instructionComponentForOrderType() {
                let customComponent = 'OrderInstructions' + (this.sessOrderTypeRaw);
                if (this.componentExists(customComponent)) {
                    return customComponent;
                } else {
                    return 'OrderInstructions';
                }
            }
        },
    }
</script>

<style scoped>

    .thank-you-container {
        max-width: 730px;
    }

    .modifierItem + .modifierItem:before {
        content: ", ";
    }

</style>

