<template>
    <div></div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import Gtag from "../mixins/Gtag";
    import Gtm from "../mixins/Gtm";

    export default {
        name: "GTagPurchase",
        mounted() {
            if (this.isGtagTrackingEnabled) {
                this.$gtag.purchase(this.gTagData);
            }

            if (this.isGTMTrackingEnabled) {
              this.gtmPurchaseEvent(this.gTagData);
            }
        },
        mixins: [Gtag, Gtm],
        methods: {
            totalItemValue(code) {
                let result = 0;
                for (let t in this.orderTotals) {
                    if (this.orderTotals[t].code === code) {
                        result = this.orderTotals[t].value;
                        break;
                    }
                }
                return result.toFixed(2);
            }
        },
        computed: {
            ...mapGetters({
                orderId: 'session/orderId',
                basket: 'session/basket',
                language: 'session/language',
                currency: 'session/currency',
                orderTotals: 'session/orderTotals'
                /*
    storeId: null,
    tableNumber: null,
    orderType: null,
    orderId: null,
    orderTotals: [],
    orderProgress: APIOrder.Constants.OrderProgress.INBOUND,
    gpsLocation: null,
    location: null,
    fulfilmentDate: null,
    fulfilmentTime: null,
    fulfilmentTimes: null,
    latestFulfilmentTime: null,
    customer: null,
    currency: 'GBP',
    language: 'en-GB',
    basket: [],
    basketQuantities: [],
    upsells: [],
    guest: {},
    vehicle: null,
    guestMarketing: false,

 */

            }),
            store() {
                let store = this.$store.getters['app/stores'][this.$store.getters['session/storeId']];
                if (typeof store === 'undefined') return '';
                return store.name;
            },
            gTagData() {
                return {
                    "affiliation": this.store,
                    "transaction_id": this.orderId,
                    "value": this.orderTotal,
                    "tax": this.orderTax,
                    "currency": this.currency,
                    "items": this.basketItems
                }
            },
            basketItems() {

                let items = [];
                for (let b in this.basket) {
                    let bItem = this.basket[b];
                    let variant = [];
                    for (let mg in bItem.modifiers) {
                        const modGrp = bItem.modifiers[mg];
                        for (let m in modGrp.modifiers) {
                            variant.push(modGrp.modifiers[m].code);
                        }
                    }
                    items.push({
                        id: bItem.code,
                        name: bItem.name[this.language],
                        category: bItem.categoryCode,
                        quantity: bItem.quantity,
                        price: (bItem.price / 100.0).toFixed(2),
                        variant: variant.join(',')
                    });
                }

                return items;

            },
            orderTotal() {
                return this.totalItemValue('total');
            },
            orderTax() {
                return this.totalItemValue('tax');
            }
        }
    }
</script>

<style scoped>

</style>
